<template>
  <div>
    <div class="main" id="invite-activity" :class="!isApp ? 'appTop' : ''">
      <div class="head">
        <div class="">
          <div class="">现金收益：</div>
          <div class="num">
            <span>{{ (showMoney / 100).toFixed(2) }}</span
            >元
          </div>
        </div>
        <div class="btn" @click="getMoney" :style="{ opacity: showMoney != 0 ? 1 : 0.7 }">领取</div>
      </div>
      <div class="mingxi-title">
        <div class="left"></div>
        明细
        <div class="right"></div>
      </div>
      <div class="list-content">
        <div class="list-item" v-for="(item, index) in list" :key="index">
          <div class="item-left">
            <div class="title">{{ item.desc }}</div>
            <div class="time">
              {{
                item.type == 1 || item.type == 3
                  ? '失效时间：' + getTime(item.expire_time)
                  : ' 领取时间：' + getTime(item.created_time)
              }}
            </div>
          </div>
          <div class="item-right">
            <div class="">
              <div class="content" v-if="item.link_url" @click="urlClick(item.link_url)">
                查看活动详情 <van-icon name="arrow" />
              </div>
            </div>
            <div class="change_num">
              {{ item.type == 1 ? '+' : ' ' }} {{ item.change_num / 100 }}元
            </div>
          </div>
          <!-- <div class="">
        <div class="title">{{ item.name }}</div>
        <div class="content" @click="urlClick(item.url)">
          查看活动详情 <van-icon name="arrow" />
        </div>
        <div class="time" v-if="item.status != 1">失效时间：{{ getTime(item.expire_time) }}</div>
      </div>
      <div class="">
        <div
          class="btn"
          :class="item.status != 0 ? 'gre-btn' : ''"
          v-if="item.status != 3"
          @click="openGetMoneyPop(item)"
        >
          {{ btnStatus[item.status] }}
        </div>
        <div class="btn-kf" v-else @click="kefuShow = true">联系客服</div>
      </div> -->
        </div>
      </div>
      <div v-if="!list.length" class="dataNullBox">
        <div>
          <img src="./../imgs/comm/dataNull.png" alt="" srcset="" />
        </div>
        <div class="text">暂无红包记录</div>
      </div>
      <!-- 失败弹窗 -->
      <van-popup class="vantPop" v-model:show="kefuShow">
        <div class="popup">
          <div class="content t-c">
            <div class="title">领取失败，请联系客服</div>
            <div>电话：{{ dh }}</div>
            <div>Q Q：{{ qq }}</div>
            <div>微信：{{ wx }}</div>
          </div>
          <div class="btn">
            <div @click="kefuShow = false">知道了</div>
          </div>
        </div>
      </van-popup>
      <!-- 支付宝领取弹窗 -->
      <van-popup class="vantPop" v-model:show="getMoneyPop">
        <div class="popup getMoneyPopStyle">
          <div class="title t-c">
            恭喜获得<span style="color: #3cdbe8"> {{ showMoney / 100 }} </span>元
          </div>
          <div class="tip t-c">填写支付宝账号后立即领取</div>
          <div class="inputBox">
            <van-field
              class="input"
              center
              :border="false"
              disabled
              :label-width="labelWidth"
              v-model="dest_name"
              label="姓名"
            />
          </div>
          <div class="inputBox">
            <van-field
              class="input"
              type="digit"
              :label-width="labelWidth"
              :border="false"
              center
              v-model="dest_account"
              maxlength="11"
              label="账号"
              placeholder="请输入支付宝绑定的手机号"
            />
          </div>

          <div
            style="
              padding: 0 20px 15px 20px;
              text-align: left;
              font-size: 12px;
              color: rgba(255, 255, 255, 0.7);
            "
          >
            <div class="t-c">所填支付宝账号需与当前名字一致</div>
          </div>

          <div
            class="submitGet"
            :class="dest_account.length !== 11 ? 'disSubmitGet' : ''"
            @click="submitGet"
          >
            提交领取
          </div>
          <div class="t-c hours"><van-icon name="warning-o" />每小时只能提交领取1次</div>
        </div>
      </van-popup>

      <!-- 银行领取弹窗 -->
      <van-popup class="vantPop" v-model:show="getMoneyBankPop">
        <div class="popup getMoneyPopStyle">
          <div class="title t-c">
            恭喜获得<span style="color: #3cdbe8"> {{ showMoney / 100 }} </span>元
          </div>
          <div class="tip t-c">提交信息后，3-5个工作日内到账</div>
          <div class="bank-box">
            <div class="inputBox">
              <van-field
                class="input"
                center
                :border="false"
                disabled
                :label-width="labelWidth"
                v-model="bankBankForm.dest_name"
                label="姓名"
              />
            </div>
            <div class="inputBox">
              <van-field
                class="input"
                type="digit"
                :label-width="labelWidth"
                :border="false"
                center
                disabled
                v-model="bankBankForm.myMobile"
                maxlength="11"
                label="手机号"
              />
            </div>

            <div class="tips-cen">领取仅限使用本人银行卡</div>

            <div class="inputBox">
              <van-field
                class="input"
                center
                type="digit"
                :border="false"
                :label-width="labelBankWidth"
                v-model="bankBankForm.dest_account"
                label="银行卡号"
                placeholder="请填写银行卡号"
              />
            </div>
            <div class="inputBox">
              <van-field
                class="input"
                center
                type="digit"
                :border="false"
                :label-width="labelBankWidth"
                v-model="bankBankForm.reserved_mobile"
                label="预留手机号"
                placeholder="请填写银行预留手机号"
              />
            </div>
            <div class="inputBox">
              <van-field
                class="input"
                center
                :border="false"
                :label-width="labelBankWidth"
                v-model="bankBankForm.account_addr"
                label="开户地"
                placeholder="请填写开户地"
              />
            </div>
            <div class="inputBox">
              <van-field
                class="input"
                center
                :border="false"
                :label-width="labelBankWidth"
                v-model="bankBankForm.withdraw_bank"
                label="开户银行"
                placeholder="请填写开户银行"
              />
            </div>
            <div class="inputBox">
              <van-field
                class="input"
                center
                :border="false"
                :label-width="labelBankWidth"
                v-model="bankBankForm.withdraw_sub_bank"
                label="开户支行"
                placeholder="请填写开户支行"
              />
            </div>
          </div>

          <div
            class="submitGet"
            :class="dest_account.length !== 11 ? 'disSubmitGet' : ''"
            @click="submitGetBank"
          >
            提交领取
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, inject } from 'vue';
import { nftUtils } from '@/utils';
import moment from 'moment';
import { useRouter } from 'vue-router';
import {
  withdrawlist,
  withdrawways,
  getactivityinfo,
  withdraw,
  withdrawchangelist,
  redremain,
  remainwithdraw
} from '@/service/invite';
import { sandpayCloud } from '@/service/user';
import { getUserCollection } from '@/service/user';
import { Toast } from 'vant';
export default {
  setup() {
    const router = useRouter();
    const toastFn = inject('$toast');
    const state = reactive({
      isApp: nftUtils.isInApp(),
      list: [
        // {
        //   id: 1,
        //   user_id: 315,
        //   type: 1,
        //   change_num: 100,
        //   desc: '因为残疾，他走起',
        //   link_url: '',
        //   created_time: 1658799648000
        // }
      ],
      btnStatus: {
        '-1': '已失效',
        0: '待领取',
        1: '已领取',
        2: '已提交'
      },
      dh: 4006161961,
      qq: 4006161961,
      wx: 'kanman2022',
      kefuShow: false,
      bankBankForm: {
        myMobile: '',
        dest_account: '',
        dest_name: '',
        withdraw_bank: '', //开户银行
        withdraw_sub_bank: '', //开户支行
        account_addr: '', //开户地
        reserved_mobile: '' //预留手机号
      },
      dest_account: '',
      dest_name: '',
      getMoneyPop: false, //支付宝提现弹窗
      getMoneyBankPop: false, //银行卡提现弹窗
      carveupPrice: 0,
      idName: null,
      labelWidth: 50,
      labelBankWidth: 80,
      activityId: null,
      id: null,
      showMoney: 0
    });
    onMounted(async () => {
      getList();
      getUserInfo();
      getRem();
    });

    const getRem = async () => {
      const { data } = await redremain();
      state.showMoney = data;
    };
    const getList = async () => {
      const { data } = await withdrawchangelist();
      state.list = data;
    };
    // 获取格式时间
    const getTime = (time, format = 'YYYY-MM-DD HH:mm:ss') => {
      return moment(time).format(format);
    };

    //新版本提现
    const getMoney = async () => {
      if (state.showMoney == 0) {
        return;
      }
      if (!state.idName) {
        router.push({ path: '/authentication' });
      }
      withdrawways().then(res => {
        console.log(res);
        if (res.status == 0) {
          //   state.withdrawType = res.data.withdraw_way;
          if (res.data.withdraw_way == 1) {
            state.getMoneyPop = true;
            // state.getMoneyBankPop = true;
          } else if (res.data.withdraw_way == 2) {
            state.getMoneyBankPop = true;
          } else {
            // 提现到三得钱包
            submitGetSande();
          }
        }
      });
    };
    // 打开领取现金弹窗
    const openGetMoneyPop = async item => {
      //  不可领取;
      if (item.status != 0) {
        return;
      }
      if (!state.idName) {
        router.push({ path: '/authentication' });
      }
      state.activityId = item.activity_id;
      state.id = item.id;
      // getInfo(item.activity_id);
      state.carveupPrice = item.price;
      withdrawways().then(res => {
        console.log(res);
        //   state.withdrawType = res.data.withdraw_way;
        if (res.data.withdraw_way == 1) {
          state.getMoneyPop = true;
          // state.getMoneyBankPop = true;
        } else {
          state.getMoneyBankPop = true;
        }
      });
    };
    //
    const urlClick = url => {
      window.location.href = url;
    };
    // 获取活动信息（用于判断按钮状态）
    const getInfo = activityId => {
      getactivityinfo({
        activity_id: activityId
      }).then(res => {
        console.log('活动信息', res);
        // state.activityStatus = res.data.status;
        // state.carveupStatus = res.data.carveup_status;
        // state.carveupStatus = 8;
        state.carveupPrice = res.data.carveup_price;

        // state.count_down = res.data.count_down;
      });
    };
    // 获取用户信息
    const getUserInfo = async () => {
      const { data, status } = await getUserCollection();
      if (data && status === 0) {
        if (!data.id_name) {
          // router.push({ path: '/authentication' });
          return;
        } else {
          state.dest_name = formatName(data.id_name);
          state.dest_account = data.mobile;
          state.idName = data.id_name;
          state.bankBankForm = {
            myMobile: data.mobile,
            dest_account: '',
            dest_name: formatName(data.id_name),
            withdraw_bank: '', //开户银行
            withdraw_sub_bank: '', //开户支行
            account_addr: '', //开户地
            reserved_mobile: '' //预留手机号
          };
        }
      }
    };
    // 提取到三德
    const submitGetSande = () => {
      Toast.loading({
        message: '处理中...',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 0
      });
      remainwithdraw({
        //  activity_id: state.activityId,
        //  id: state.id,
        amount: state.showMoney,
        withdraw_way: 3,
        dest_account: state.dest_account,
        dest_name: state.idName
      }).then(res => {
        Toast.clear();
        getList();
        getRem();
        if (res.status === 0) {
          toastFn({ message: '领取成功，请到钱包查看' });

          nftUtils.setcnzzRecordByBtn('成功领取现金');
        } else if (res.status === 601) {
          state.kefuShow = true;
        } else if (res.status === 602) {
          let str =
            res.data > 60
              ? `领取失败,请${Math.ceil(res.data / 60)}分钟后再次领取`
              : `领取失败,请${res.data}秒后再次领取`;
          toastFn({ message: str, duration: 3000 });
        } else if (res.status == 421) {
          toastFn({ message: res.message, duration: 3000 });
          sandpayCloud({}).then(res => {
            console.log(res);
            if (res.status == 0) {
              window.location.href = res.data.url;
            }
          });
        } else {
          toastFn({ message: res.message, duration: 3000 });
        }
      });
    };
    // 确认领取现金
    const submitGet = () => {
      if (state.dest_account.length == 11) {
        Toast.loading({
          message: '处理中...',
          forbidClick: true,
          loadingType: 'spinner',
          duration: 0
        });
        remainwithdraw({
          //  activity_id: state.activityId,
          //  id: state.id,
          amount: state.showMoney,
          withdraw_way: 1,
          dest_account: state.dest_account,
          dest_name: state.idName
        }).then(res => {
          state.getMoneyPop = false;
          //   getInfo();
          Toast.clear();
          getList();
          getRem();
          if (res.status === 0) {
            toastFn({ message: '领取成功' });

            nftUtils.setcnzzRecordByBtn('成功领取现金');
          } else if (res.status === 601) {
            state.kefuShow = true;
          } else if (res.status === 602) {
            let str =
              res.data > 60
                ? `领取失败,请${Math.ceil(res.data / 60)}分钟后再次领取`
                : `领取失败,请${res.data}秒后再次领取`;
            toastFn({ message: str, duration: 3000 });
          } else {
            toastFn({ message: res.message, duration: 3000 });
          }
        });
      }
    };
    const submitGetBank = () => {
      if (
        state.dest_account.length == 11 &&
        state.bankBankForm.dest_account &&
        state.bankBankForm.withdraw_bank &&
        state.bankBankForm.account_addr &&
        state.bankBankForm.reserved_mobile
      ) {
        Toast.loading({
          message: '处理中...',
          forbidClick: true,
          loadingType: 'spinner',
          duration: 0
        });
        remainwithdraw({
          // activity_id: state.activityId,
          // id: state.id,
          amount: state.showMoney,
          withdraw_way: 2,
          dest_name: state.idName,

          dest_account: state.bankBankForm.dest_account,
          withdraw_bank: state.bankBankForm.withdraw_bank, //开户银行
          withdraw_sub_bank: state.bankBankForm.withdraw_sub_bank, //开户支行
          account_addr: state.bankBankForm.account_addr, //开户地
          reserved_mobile: state.bankBankForm.reserved_mobile //预留手机号
        }).then(res => {
          state.getMoneyBankPop = false;
          // getInfo();
          Toast.clear();
          getList();
          getRem();
          if (res.status === 0) {
            toastFn({ message: '已提交领取' });
            nftUtils.setcnzzRecordByBtn('成功领取现金');
          } else {
            toastFn({ message: res.message, duration: 3000 });
          }
        });
      } else {
        toastFn({ message: '请填写所有信息', duration: 3000 });
      }
    };

    // 名字第一个字替换成*
    function formatName(str) {
      return new Array(str.length - 1).join('*') + str.substr(-2);
    }
    // 获取用户信息

    return {
      ...toRefs(state),
      getTime,
      openGetMoneyPop,
      formatName,
      getInfo,
      getUserInfo,
      submitGet,
      getList,
      submitGetBank,
      urlClick,
      getRem,
      getMoney,
      submitGetSande
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
.mingxi-title {
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  .left {
    height: 2px;
    width: 136px;

    background: linear-gradient(270deg, rgba(255, 222, 195, 1), rgba(255, 255, 255, 0));
  }
  .right {
    height: 2px;
    width: 136px;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0), rgba(255, 222, 195, 1));
  }
}
.head {
  width: 343px;
  height: 126px;
  background-image: url('https://activity-resource.321mh.com/nft/red_packet/bg.png');
  background-size: 100%;
  color: #333333;
  box-sizing: border-box;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 16px;
  .num {
    span {
      font-size: 36px;
      font-weight: 600;
    }
  }
  .btn {
    width: 92px;
    height: 38px;
    background: linear-gradient(180deg, #3e3065 0%, #1c172a 100%);
    border-radius: 25px 25px 25px 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #ffffff;
  }
}
.dataNullBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  img {
    width: 150px;
    height: 110px;
  }

  .text {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
  }
}
.getMoneyPopStyle {
  text-align: center;
  padding: 32px 0 24px 0;
  .tips-cen {
    padding: 0 20px 0 20px;
    text-align: left;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
  }
  .bank-box {
    height: 280px;
    overflow: auto;
  }
  .input {
    background: #1c172a;
    border-radius: 8px;
    color: white;
    margin: 20px 0;
    font-size: 14px;
    font-weight: 400;
  }

  .title {
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
  }

  .tip {
    margin-top: 5px;
    color: rgba(255, 255, 255, 0.7);
  }

  .inputBox {
    padding: 0 20px;
  }

  .submitGet {
    width: 116px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    margin: 0 auto;
    color: #1c172a;
    background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
    border-radius: 8px 8px 8px 8px;
    font-weight: 500;
  }

  .disSubmitGet {
    background: linear-gradient(132deg, #5b8d80 0%, #248795 100%);
  }
}
.getMoneyPopStyle {
  text-align: center;
  padding: 32px 0 24px 0;
  .input {
    background: #1c172a;
    border-radius: 8px;
    color: white;
    margin: 20px 0;
    font-size: 14px;
    font-weight: 400;
  }

  .title {
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
  }

  .tip {
    margin-top: 5px;
    color: rgba(255, 255, 255, 0.7);
  }

  .inputBox {
    padding: 0 20px;
  }

  .hours {
    margin-top: 12px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
  }

  .submitGet {
    width: 116px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    margin: 0 auto;
    color: #1c172a;
    background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
    border-radius: 8px 8px 8px 8px;
  }

  .disSubmitGet {
    background: linear-gradient(132deg, #5b8d80 0%, #248795 100%);
  }
}
.t-c {
  text-align: center;
}
.popup {
  width: 300px;
  background: #2f293f;
  border-radius: 8px 8px 8px 8px;
  text-align: left;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  --van-field-placeholder-text-color: rgba(255, 255, 255, 0.3);
  .content {
    padding: 30px 16px;
    line-height: 26px;
    .title {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 16px;
    }
  }

  .btn {
    div {
      text-align: center;
      line-height: 50px;
      font-size: 16px;
      border-top: 1px solid #494359;
    }
  }
}
.appTop {
  padding-top: 70px !important;
}
.main {
  padding: 24px 16px;
  box-sizing: border-box;
}
.list-content {
  padding-bottom: 76px;
}
.list-item {
  padding: 16px;
  border-radius: 8px 8px 8px 8px;
  background: #2f293f;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  font-size: 12px;
  .item-left {
    max-width: 70%;
  }
  .item-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 12px;
    // width: 10%;
    word-break: break-all;

    display: -webkit-box;

    -webkit-line-clamp: 2;

    -webkit-box-orient: vertical;

    overflow: hidden;
  }
  .content {
    color: $primary;
    margin: 0px 0 12px;
  }
  .change_num {
    font-size: 16px;
    font-weight: 500;
    text-align: end;
  }
  .time {
    color: rgba(255, 255, 255, 0.7);
  }
  .btn {
    width: 80px;
    height: 32px;

    background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
    border-radius: 8px;
    color: #1c172a;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .gre-btn {
    opacity: 0.5;
  }
  .btn-kf {
    width: 80px;
    height: 32px;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
<style lang="scss">
#invite-activity {
  .vantPop {
    background: transparent !important;
  }

  .van-field__label,
  .van-field__value,
  .van-field__control {
    color: white;
  }

  .shareBtn,
  .posterBtn {
    text-align: center;
    width: 105px;
    height: 36px;
    background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
    border-radius: 4px 4px 4px 4px;
    font-size: 11px;
    font-weight: 600;
    line-height: 36px;
    color: #1c172a;
  }

  .qrcodeImg {
    top: 292px;
  }
}
</style>
